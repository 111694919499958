// CURSORS
.cursor-default {
    cursor: default;
}
.cursor-auto {
    cursor: auto;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-text {
    cursor: text;
}
.cursor-grab {
    cursor: grab;
}

// LINE HEIGHT
.lh-normal {
    line-height: normal !important;
}

// ADDITIONAL MUTED TEXT
.text-muted-2 {
    color: rgb(140 152 164 / 60%) !important;
}
.text-muted-3 {
    color: rgb(140 152 164 / 40%) !important;
}

// FONT SIZE
.fs-xxs {
    font-size: xx-small;
}
.fs-xs {
    font-size: x-small;
}
.fs-sm {
    font-size: small;
}
.fs-md {
    font-size: medium;
}
.fs-lg {
    font-size: large;
}
.fs-xl {
    font-size: x-large;
}
.fs-xxl {
    font-size: xx-large;
}
.fs-smaller {
    font-size: smaller;
}
.fs-larger {
    font-size: larger;
}

// TRANSITIONS
.transition {
    transition-duration: 0.3s;
}
.transition-xs {
    transition-duration: 0.1s;
}
.transition-md {
    transition-duration: 0.3s;
}
.transition-lg {
    transition-duration: 0.4s;
}
.transition-xl {
    transition-duration: 0.5s;
}

// OPACITY
.opacity-0 {
    opacity: 0;
}

// MANUALLY INPUT GROUP STYLING
.input-group-start {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-middle {
    border-radius: 0;
}
.input-group-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
        .invalid-tooltip
    ):not(.invalid-feedback) {
    .input-group-child {
        margin-left: -0.0625rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select,
.input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    .input-group-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
