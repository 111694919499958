@charset "UTF-8";

@import 'nml';
@import 'front';
@import 'custom-theme-dark';
@import 'utilities';
@import 'google-maps-autocomplete';

:root {
    --nml-color: #00a651;
    --ucc-color: #00aeef;
    --collections-color: #f58025;
}


/* Global scrollbar styling */
* {
scrollbar-width: thin; /* Firefox */
scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Firefox */
}

/* WebKit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
width: 8px;
height: 8px;
}

*::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.5);
border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
background-color: rgba(0, 0, 0, 0.7);
}

*::-webkit-scrollbar-track {
background: transparent;
}


// .main-content {
//     margin-top: var(--navbar-height);
// }

html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    // margin-top: 50px;
    font-size: 14px;
    overscroll-behavior: none;
}
body.embed {
    margin-top: 0;
}

// Hide zendesk button
iframe[title='Opens a widget where you can find more information'] {
    display: none;
}

.link-ncs {
    text-decoration: underline;
}
.link-dash-well {
    color: white;
    text-decoration: underline;
}

.text-color-nml {
    color: var(--nml-color);
}

.text-color-ucc {
    color: var(--ucc-color);
}

.text-color-collections {
    color: var(--collections-color);
}

.page {
    margin: 0 55px;
    padding-top: 15px;
    padding-bottom: 110px;
}
@media (max-width: 767px) {
    .page {
        margin: 0 10px;
        padding-top: 15px;
        padding-bottom: 50px;
    }
}
body.embed .page {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.skeleton {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
    * {
        display: none;
    }
}

.skeleton.row {
    margin-left: 0px;
    margin-right: 0px;
}

.skeleton-title {
    width: 25%;
    height: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.125rem;
}

.skeleton-text {
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.skeleton-text:last-child {
    margin-bottom: 0;
    width: 80%;
}

.skeleton-block {
    border-radius: 0.125rem;
    margin-bottom: 0.25rem;
    height: 4rem;
    width: 100%;
}

.skeleton-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 1rem;
    flex-shrink: 0;
    margin-bottom: 0.25rem;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.btn-spacing {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    cursor: default;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.85rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.wrapper {
    min-height: 100%;
    height: auto;
    margin: 0px 55px -120px 0;
    padding-bottom: 125px;
}
.hex-logo {
    margin-top: -10px;
}
.fill-height {
    min-height: 100%;
    height: auto;
}

.add-gutter {
    padding: 0.5rem;
}

.relative {
    position: relative;
}

.well-ncs {
    min-height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-top: -20px;
    /* background-color: #; */
    border: 0px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.navbar-ncs {
    background-image: -webkit-linear-gradient(top, #465964 0%, #465964 100%);
    background-image: -o-linear-gradient(top, #465964 0%, #465964 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#465964), to(#465964));
    background-image: linear-gradient(to bottom, #465964 0%, #465964 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    background-repeat: repeat-x;
}
.navbar-ncs .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
    background-image: -webkit-linear-gradient(top, #465964 0%, #465964 100%);
    background-image: -o-linear-gradient(top, #465964 0%, #465964 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#465964), to(#465964));
    background-image: linear-gradient(to bottom, #465964 0%, #465964 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#465964', endColorstr='#465964', GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}
.navbar-ncs .navbar-brand,
.navbar-ncs .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-brand {
    color: #fff;
}

.navbar-brand:hover {
    color: #fff;
}

.nav-ncs {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-ncs > li > a {
    color: #000;
    text-decoration: none;
    list-style: none;
}

.ncs-tabs.nav-tabs {
    margin-bottom: 15px;
    .nav-item {
        .nav-link {
            border-color: #ffffff00;
            transition: border 0.5s;
            transition-timing-function: ease;
            color: #979797;
        }
        .nav-link:hover {
            border-color: #ffffff00;
            border-bottom: 2px solid #00000012;
        }
        .nav-link.active {
            font-weight: bold;
            color: #495057;
            border-bottom: 2px solid #8d8dff;
            background-color: transparent;
            padding-left: calc(1rem * 0.9);
            padding-right: calc(1rem * 0.9);
        }
    }
    .nav-item:hover {
        background-color: #00000008;
        border-radius: 6px 6px 0px 0px;
    }
}

.ncs-modal .modal-dialog {
    .modal-content {
        box-shadow: rgb(0 0 0 / 40%) 0px 30px 90px;
        border: 2px solid rgb(255 255 255 / 22%);
        border-radius: 15px;
        .modal-header {
            padding: 25px 25px 20px 25px;
            border-bottom: initial;
            .modal-title {
                font-size: 1.1rem;
                line-height: 1.05;
                font-weight: 550;
            }
            .close {
                font-weight: initial;
            }
        }
        .modal-body {
            padding: 0px 25px;
            padding-bottom: 35px;
        }
        .modal-footer {
            border-bottom-right-radius: 14px;
            border-bottom-left-radius: 14px;
            border-top: 2px solid #efeff6a1;
        }
    }
}

.ncs-modal-subtitle {
    color: lightgrey;
    font-size: 0.825rem;
    font-weight: 500;
}

.ncs-btn {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);
    border-color: rgb(209 213 219);
    border-color: initial;
    border-radius: 0.25rem;
}

.nav-ncs > li > a:focus,
.nav-ncs > li > a:hover {
    text-decoration: underline;
}
.sidebar-ncs {
    height: auto;

    /*background-color: #eee;
  border-right: thin solid #000;*/
}
.dashboard-middle {
    position: fixed;
    left: 215px;
}
.dashboard-pannel {
}
.text-center {
    text-align: center;
}
.well-center {
    min-height: 96px;
    position: relative;
}
.well-info-icon {
    position: absolute;
    bottom: 12px;
    right: 8px;
    z-index: 100;
}
.sidebar-group-ncs {
    margin-bottom: 30px;
    border-bottom: thin solid #000;
}
.dash_header {
    font-size: 1.5em;
}
.dash_header_report_link {
    text-align: right;
}

.dashboard-well {
    font-size: 4em;
    padding: 20px;
}
.dashboard-well-text {
    font-size: 1.1em;
    line-height: 1em;
}
.dashboard-well-line {
    margin-top: 7px;
    display: block;
}

.dashboard-well-text-num {
    font-size: 1.8em;
    line-height: 1.8em;
}

.dashboard-well-text-num2 {
    font-size: 1.3em;
    line-height: 2.25em;
}

@media (max-width: 1155px) {
    .dashboard-well {
        font-size: 2em;
    }
    .dashboard-well-text {
        font-size: 0.8em;
    }
    .dashboard-well-text-num {
        font-size: 1.3em;
    }

    .dashboard-well-text-num2 {
        font-size: 1em;
    }
}

.sidebar-header-ncs {
    white-space: nowrap;
    color: #000;
    font-size: 1.1em;
}
.sidebar-text-ncs {
    white-space: nowrap;
    font-size: 1em;
}

.sidebar li a {
    line-height: 30px;
    height: 30px;
    padding-top: 0;
}

.csg_filter {
    width: 200px;
}

.footer a {
    font-size: 10px;
    color: #fff;
}

.footer {
    height: 50px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #465964;
    color: #fff;
}
.footerLeft {
    padding-top: 10px;
    position: relative;
    text-align: left;
    font-size: 1.1em;
    float: left;
}

.footerRight {
    padding-top: 15px;
    position: relative;
    text-align: right;
    font-size: 1.1em;
    float: right;
}

.socal {
    font-size: 2em;
}

.carousel-inner > .item > img {
    margin: 0 auto;
}
.carousel-inner img {
    margin: auto;
}
.carousel-control .fa-chevron-left,
.carousel-control .icon-prev {
    margin-left: -15px;
}

.carousel-control .fa-chevron-right,
.carousel-control .icon-next {
    margin-right: -15px;
}
.carousel-control .fa-chevron-left,
.carousel-control .fa-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control .fa-chevron-left,
.carousel-control .icon-prev {
    left: 50%;
    margin-left: -10px;
}

.carousel-control .fa-chevron-right,
.carousel-control .icon-next {
    right: 50%;
    margin-right: -10px;
}

@media screen and (min-width: 768px) {
    .carousel-control .fa-chevron-left,
    .carousel-control .fa-chevron-right,
    .carousel-control .icon-next,
    .carousel-control .icon-prev {
        width: 100px;
        height: 30px;
        margin-top: -15px;
        font-size: 30px;
    }

    .carousel-control .fa-chevron-left,
    .carousel-control .icon-prev {
        margin-left: -15px;
    }

    .carousel-control .fa-chevron-right,
    .carousel-control .icon-next {
        margin-right: -15px;
    }

    .carousel-caption {
        right: 20%;
        left: 20%;
        padding-bottom: 30px;
    }

    .carousel-indicators {
        bottom: 20px;
    }
}

#right {
    float: right;
}

@media (max-width: 850px) {
    .hidetop {
        display: none;
    }
}

.vcenter {
    vertical-align: middle;
}

.pointer {
    cursor: pointer;
}

.header-padding {
    padding: 0 5px;
}
.notification-circle {
    display: inline-block;
    height: 34px;
    width: 40px;
    line-height: 34px;

    -moz-border-radius: 30px; /* or 50% */
    border-radius: 30px; /* or 50% */

    color: white;
    text-align: center;
    font-size: 1em;
}
.no-hover:hover {
    color: white;
    text-decoration: none;
} /*
.overflow{
    overflow-y: visible;
    position: relative;
}*/
.footer-inside-modal {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    justify-content: flex-end;
}
.modal-padding {
    padding: 15px;
}
.text-white {
    color: white;
}
@media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: relative;
    }
}
@media (min-width: 768px) {
    .table-responsive {
        // overflow: visible;
    }
}
#snowflakeContainer {
    position: absolute;
    left: 0px;
    top: 0px;
}
.snowflake {
    padding-left: 15px;
    font-family: Cambria, Georgia, serif;
    font-size: 14px;
    line-height: 24px;
    position: fixed;
    color: #ffffff;
    user-select: none;
    z-index: 1000;
}
.snowflake:hover {
    cursor: default;
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu > a:after {
    display: block;
    content: ' ';
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
    color: #212529;
}

.dropdown-submenu:hover > a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
.searchclear {
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
}
.cut-off {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.search-box-style {
    padding: 10px;
    //background-color: #eeeeee;
    border-radius: 0.7em;
}
.tablesorter-background {
    background-color: white;
}
.affix {
    top: 50px;
    width: 15.8%;
}
.affix-bottom {
    position: absolute;
}
#sidebar.row.affix-bottom {
    position: relative;
    width: 117%;
}
.no-margin {
    margin: 0;
}

.doc-editor {
    width: 100%;
    height: 1000px;
}

.color-black {
    color: black;
}
.no-wrap {
    white-space: nowrap;
}
.highlight {
    background-color: #e8e8e8;
}
.toaster {
    position: fixed;
    z-index: 1031;
    display: inline-block;
    right: 10px;
    top: 20px;
}
.saved_searches.dropdown-menu > li > a:after {
    content: none;
}

@media print {
    .myDivToPrint {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        font-size: 10px;
        display: block;
    }
    .table-condensed > thead > tr > th,
    .table-condensed > tbody > tr > th,
    .table-condensed > tfoot > tr > th,
    .table-condensed > thead > tr > td,
    .table-condensed > tbody > tr > td,
    .table-condensed > tfoot > tr > td {
        padding: 2px;
    }
    .footer,
    .btn {
        display: none;
    }
    .print_area {
        display: block;
    }
}

@media screen {
    #forPrintScreenOnly {
        display: none;
    }
}

.selection-required {
    background: #fcf8e3 !important;
    color: #000;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.list-group > .ucc_list {
    margin-bottom: 0;
    border-radius: 1em;
    border: 2px solid #e3e3e3;
    /*-webkit-user-select: none; !* webkit (safari, chrome) browsers *!*/
    /*-moz-user-select: none; !* mozilla browsers *!*/
    /*-ms-user-select: none; !* IE10+ *!*/
}

.card_selected {
    position: sticky;
    top: 72px;
    bottom: 16px;
    z-index: 2;
}

.card_selected ul {
    -webkit-box-shadow: 0 6px 13px slategrey;
    -moz-box-shadow: 0 6px 13px slategrey;
    box-shadow: 0 6px 13px slategrey;
    transition: 0.5s;
}
.card_selected ul > .ucc_default {
    border: 3px solid #00aeef;
}
.card_selected ul > .ucc_danger {
    border: 3px solid #d9534f;
}
.search-box-ucc {
    margin-bottom: 0.2em;
}
.search-box-section {
    padding-bottom: 0.7em;
    //border-bottom: solid 1px darkgray;
}
.search-box-style h4 {
    margin-bottom: 5px;
}
.bkdata_ucc_priority {
    background-color: #c70012;
}
.border-top-blue {
    border-top: 5px ridge rgba(212, 221, 228, 0.5);
}
@media (max-width: 768px) {
    #launcher {
        top: -8px !important;
        right: 50px !important;
    }
}
.default_star:before {
    color: #ffd700;
    font-weight: 900 !important;
}
.default_star:hover:before {
    color: black;
    font-weight: 400 !important;
}
.make_star_default:before {
    color: black;
    content: '\f005';
}
.make_star_default:hover:before {
    color: #ffd700;
    font-weight: 900 !important;
}
button:hover > .make_star_default:before {
    color: #ffd700;
    font-weight: 900 !important;
}
.panel > .ucc_note_group:last-child .list-group-item:last-child {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}
.rotate45 {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
}
.note_type_badge {
    margin-right: 3px;
}
.fa-rotate-45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.ucc_panel_border {
    border-radius: 1em;
    margin-bottom: 10px;
}
.not_link {
    color: black;
}
.not_link:hover {
    color: black;
    text-decoration: none;
}
.ucc_priority {
    color: #d9534f;
}
.ucc_follow_up_badge {
    color: white;
    background-color: #d9534f;
}
.ucc_panel_header {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.margin_top {
    margin-top: -50px;
}
.ucc_text_danger {
    color: #d9534f;
}
.panel-heading.pointer.collapsed {
    -webkit-border-radius: 1em !important;
    -moz-border-radius: 1em !important;
    border-radius: 1em !important;
}
.ucc_panel_arrow {
    margin-left: 5px;
    transition: transform 0.38s;
    transform: rotate(180deg);
}
.collapsed .ucc_panel_arrow {
    transition: transform 0.38s;
    transform: rotate(0deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.edit_homepage_option {
    border-radius: 1em;
    border: 3px solid lightgrey;
}
.ucc_widget_item {
    flex: 1;
    text-align: center;
    padding: 5px;
}
.ucc_widget_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    margin-top: 10px;
    max-height: 400px;
}
.gray-block {
    background-color: lightgrey;
    height: 20px;
    display: inline-block;
}
.homepage-widget {
    box-shadow: lightgrey 0 10px 22px;
    margin: 35px 15px;
    border-radius: 0.5em;
    border: 2px solid #efefef;
}
.ytd_ticker {
    white-space: nowrap;
    font-size: 28px;
    margin-top: 12px;
    display: block;
}
.text-green {
    color: #009800d6;
}
.text-red {
    color: #d9534f;
}
.button-container {
    display: flex;
    align-content: center;
    margin-top: -10px;
    margin-bottom: 10px;
}
.button-items {
    flex: 1;
    text-align: center;
}
.service_select:hover {
    background-color: #f5f5f5;
}

.copy-section-header {
    margin: 20px 0px;
}

.copy-icon {
    padding-right: 9px;
    color: grey;
}

.check-icon {
    color: green;
}

.copy-data-row {
    margin: 7px 0;
    padding: 6px 0;
}

.copy-label {
    text-align: right;
    font-weight: bold;
}

.inline-copy {
    color: #6d6c6c;
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    padding: 5px;
    padding-right: 13px;
    border-radius: 5px;
}

.limit-size {
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.limit-search-box-size {
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.inline-copy:hover {
    background-color: rgb(224, 224, 224);
}

.inline-copy:active {
    background-color: rgb(209, 209, 209);
}

.inline-copy .far {
    padding-left: 10px;
}

.docuware-list {
    overflow: auto;
    /* height: 500px; */
    /* display: block; */
}

.docuware-list th,
.docuware-list td {
    width: 300px;
    vertical-align: middle;
}

.docuware-modal .modal-content .docuware-content {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.doc-upload-form {
    padding: 7px;
}

.ucc_alert {
    z-index: 9999;
    right: 20px;
    top: 20px;
    list-style-type: none;
    position: fixed;
}

@media (max-width: 767px) {
    .ucc_alert {
        bottom: 0;
        right: 0;
        top: auto;
        width: 100%;
    }
}

.alert_button_wrapper {
    top: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-top: -12px;
    padding: 0;
    margin-bottom: -13px;
    display: flex;
    margin-right: -5px;
}

.alert_button_group {
    display: flex;
    flex-direction: column;
    flex: 1 100%;
}

.alert_button_group .alert_button:first-child {
    border-top: 0;
}

.alert_button_group .alert_button:last-child {
    border-bottom: 0;
}

.alert_button {
    border-left: 2px solid #cecece;
    border-right: 0;
    flex: 1 auto;
    background-color: transparent;
    cursor: pointer;
}

.alert_button:hover {
    background-color: #a1cce2;
}

.reply_wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    display: flex;
}

.reply_group {
    display: flex;
    flex-direction: row;
    flex: 1 100%;
}

.reply_button {
    border-top: 2px solid #cecece;
    flex: 1 auto;
    background-color: transparent;
    cursor: pointer;
}

.reply_group .reply_button:last-child {
    border-left: 2px solid #cecece;
    border-bottom-right-radius: 0.25em;
}

.reply_group .reply_button:first-child {
    border-left: 2px solid #cecece;
    border-bottom-left-radius: 0.25em;
}

.reply_button:hover {
    background-color: #a1cce2;
}

.reply-note {
    padding: 5px 10px;
    border-bottom: 2px solid #cecece;
}

.reply-container .reply-note:last-child {
    border-bottom: 1px solid #cecece;
}

.ucc_note_group > span:first-child > .parent_note {
    border-top: none;
}
.ucc_note_group > span:last-child > .list-group-item {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}

.ucc_note_tabs > li {
    margin-left: -1px;
}

@keyframes slideInFromRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}

@-webkit-keyframes slideInFromRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}

@-moz-keyframes slideInFromRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}

@keyframes slideOutToRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    100% {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
        visibility: hidden;
    }
}

.ucc-alert-item {
    animation-name: slideInFromRight;
    animation-fill-mode: both;
    animation-duration: 1s;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    transform: translateX(0);
    box-shadow: 0 6px 13px slategrey;
}

.hide-alert {
    animation-name: slideOutToRight;
    animation-fill-mode: both;
    animation-duration: 1s;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    transform: translateX(0);
}

.col-xs-0,
.col-sm-0,
.col-md-0,
.col-lg-0 {
    flex: 0 0 0;
    width: 0%;
    position: relative;
    float: left;
    min-height: 1px;
    opacity: 0;
}

#save-template-text {
    transition: 0.6s;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background: white;
}
#save-template-button {
    color: #696969;
}
/* #save-template-text:hover {
	color: white;
	background: rgb(200, 200, 200);
	box-shadow: 0 0 0 5px rgb(61, 71, 82);
}
#save-template-text:hover #save-template-button:hover {    color: white    } */
.save-template-text-focus {
    transition: 0.6s;
    border: none;
    outline: none;
    border-radius: 20px;
    border: none;
    box-shadow: none;
    padding-left: 35px;
    cursor: text;
    width: 300px;
    height: 30px;
    background: #f7f7f7;
    color: black;
}

.save-template-text-blur {
    text-indent: -5000px;
    width: 30px;
    height: 15px;
    transition: 0.6s;
    background: white;
    border-radius: 20px;
    border: none;
}
#save-template-button:focus {
    color: black;
}

#save-template-button {
    position: relative;
    right: -25px;
    color: #3e3e3e;
    cursor: pointer;
}
.simple-button {
    transition: 0.2s;
}
.simple-button:hover {
    color: #63c0e6;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 2;
}

.table-row-selected td {
    background-color: #f0f5f7;
}

.flex-copy {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.flex-label {
    flex-basis: 40%;
    white-space: nowrap;
    overflow-wrap: break-word;
}

.flex-copy-button {
    flex-basis: 60%;
    white-space: nowrap;
    overflow-wrap: break-word;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin: 20px;
}

.purple {
    color: #b300b3;
}

.flex-bottom {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    padding: 0;
}

.flex-top {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    padding: 0;
}

.flex-stretch {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-content: stretch;
}

.flex-button {
    flex: 1;
    background-color: transparent;
    padding: 8px;
}

.flex-stretch > .flex-button:first-child {
    border-left: transparent;
    /*border-bottom-left-radius: 1em;*/
}

.flex-stretch > .flex-button:last-child {
    border-right: transparent;
    /*border-bottom-right-radius: 1em;*/
}

.flex-bottom > .flex-stretch > .flex-button {
    border-bottom: transparent;
}

.flex-top > .flex-stretch > .flex-button {
    border-top: transparent;
}

.no-flex-border > .flex-button {
    border-left: transparent;
    border-right: transparent;
}

.flex-button:hover {
    background-color: #cecece40;
}

.hidden {
    display: none !important;
}

.ucc_closed {
    background-image: none !important;
    background-color: #d4d4d4 !important;
}

.ucc_open {
    background-image: none !important;
    background-color: #f1f1f1 !important;
}

.ucc_open.ucc_panel_danger {
    background-image: none !important;
    background-color: #f2dede !important;
}

.ucc_closed.ucc_panel_danger {
    background-image: none !important;
    background-color: #e6a6a6 !important;
}

.ucc_log.ucc_widget_item {
    border-radius: 1em;
}

.ucc_log.ucc_widget_item:hover {
    box-shadow: #e5e5e5 0 4px 12px;
    /*border: 1px solid lightgrey;*/
}

.emp-error-selected {
    -webkit-box-shadow: 0 6px 13px slategrey;
    -moz-box-shadow: 0 6px 13px slategrey;
    box-shadow: 0 6px 13px slategrey;
    transition: 0.5s;
    border: 2px solid #00aeef !important;
}

.flat-button {
    padding: 10px 15px;
    margin: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #989898;
    background-color: #efefef;
    border-radius: 7px;
    cursor: pointer;
    transition: 0.2s background-color;
    border-color: #ffffff00;
}

.flat-button:hover {
    color: #717171;
    background-color: #e6e6e6;
}

.flat-button:active {
    color: #717171;
    background-color: #dadada;
}

.search-title {
    font-size: 16px;
    color: #868686;
}

.file-dropper {
    width: 100%;
    height: 100px;
    background-color: #fdfdfd;
    border-radius: 10px;
    border: 2px dashed gainsboro;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #c5c5c5;
    font-weight: 300;
    cursor: pointer;
    transition: 0.2s;
    font-size: 15px;
}

.file-dropper:hover {
    background-color: #f7f7f7;
}

.file-dropper:active {
    background-color: #ececec;
}

.email-header {
    border-bottom: 1px solid #f3f3f3;
}

.email-header td:first-child {
    padding-top: 7px;
    vertical-align: top;
    text-align: right;
}

.email-header td:first-child span {
    color: grey;
    padding: 0px 8px;
    display: inline-block;
}

.email-header td:nth-child(2) {
    width: 100%;
}

.unstyled-input {
    border: 0px;
    box-shadow: inset 0px 0px 0px 0px white !important;
    width: 100%;
    display: inline-block;
    padding-left: 0px;
    outline: none;
}

.request-card {
    padding: 20px;
    border: 1.5px solid #e3e3e3;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    border-radius: 1em;
    margin-bottom: 15px;
}

.border-curved {
    border-radius: 1em;
}

.border-curved > *:first-child {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.border-curved > *:last-child {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}

.left-nav {
    left: 0;
    border-right: 1px solid #f3f3f3;
}

.side-nav {
    position: absolute;
    top: 0;
    // z-index: 1029;
    bottom: 0;
    width: 220px;
    background-color: #f9f9f9;
    border-right: 1px solid #e2e2e2;
    /*overflow: scroll;*/
}
.side-nav-collapsed:hover {
    left: 0;
}

.side-nav-collapsed:hover div {
    left: 0;
    float: none;
}

.side-nav-collapsed {
    /*width: 55px !important;*/
    transition: 0.3s;
    left: -165px;
    cursor: pointer;
}

.side-nav-collapsed {
    text-align: right;
}

.side-nav-collapsed:hover {
    text-align: left;
}

.side-nav-collapsed .hide-side-nav-collapse {
    display: none;
}

.side-nav-collapsed:hover .hide-side-nav-collapse {
    display: inline !important;
}

.side-nav-filters {
    padding: 15px;
    background-color: transparent;
    color: rgb(90, 90, 90);
    cursor: auto;
}

.right-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    border-left: 1px solid #f3f3f3;
}

.side-nav-header {
    padding: 15px;
    border-bottom: 1px solid #e2e2e2;
}

.side-nav-body {
    position: absolute;
    top: 112px;
    bottom: 51px;
    left: 0;
    right: 0;
    overflow: auto;
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.side-nav-footer {
    bottom: 0;
    position: absolute;
    padding: 15px 20px;
    border-top: 1px solid #e2e2e2;
    width: 100%;
    background-color: #f9f9f9;
}

.side-nav-footer:hover {
    background-color: #eeeeee;
}

.file-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    //height: 315px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e2e2e2;
    z-index: 998;
    transition: 0.1s;
}

.file-bar-collapsed {
    //height: 100px;
}

.file-bar-not-editable {
    //height: 275px;
}

.file-bar-footer {
    //position: relative;
    //bottom: 0;
    border-top: 1px solid #e2e2e2;
    //width: 100%;
    height: 20px;
    background-color: #f9f9f9;
    text-align: center;
    color: grey;
    margin-left: -15px;
    margin-right: -15px;
}

.file-bar-footer:hover {
    background-color: #eeeeee;
}

.file-content {
    margin-right: 220px;
    position: relative;
    overflow: auto;
    top: 300px;
}

.file-content-collapsed {
    margin-right: 220px;
    top: 100px;
    position: relative;
    overflow: auto;
    height: 100%;
}

.file-bar .row {
    padding: 5px;
}

.file-bar-header {
    border-bottom: 1px solid #e7e7e7;
    height: 35px;
}

.address-modal {
    max-width: 1300px;
}

.tracker-content {
    /* position: absolute; */
    /* top: 45px; */
    bottom: 0;
    margin: 0 220px;
    overflow: auto;
    padding: 0 10px;
}

.page-container {
    position: absolute;
    top: var(--navbar-height);
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

#launcher{
  display: none;
}

.page-container #app {
    height: 100%;
}

.page-container #app > div {
    height: 100%;
}

// .main-content {
//     margin-left: 220px;
//     /*margin-right: 220px;*/
//     position: relative;
//     /* margin-top: 45px; */
//     height: 100%;
//     /*overflow: scroll;*/
// }

.extra-content {
    height: 100%;
    overflow: auto;
}

.main-content-collapsed {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 55px;
    position: relative;
    height: 100%;
}

.typeaheadcard {
    margin-left: 250px;
    margin-right: 224px;
    position: relative;
    margin-top: 50px;
}

.projectcard {
    margin-left: 250px;
    margin-right: 224px;
    position: relative;
    margin-top: 50px;
}

.list-nav-item {
    border-bottom: 1px solid #e2e2e2;
    cursor: pointer;
    background-color: transparent;
    color: #272727;
}

.list-nav-item:hover {
    background-color: #e7e7e7;
}

.side-nav-footer:hover {
    background-color: #eeeeee;
}

.list-nav-item > a {
    color: #5a5a5a;
}

.list-nav-item > a:hover {
    text-decoration: none;
}

.nav-submenu {
    display: block;
    position: absolute;
    /*top: 0;*/
    left: 75%;
    z-index: 3333;
    float: left;
    margin-top: -35px;
}

.list-nav-item:hover .portal-nav-menu {
    display: block !important;
}

.list-nav-item.open {
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-left: 4px solid #00a651;
}

.list-nav-header {
    display: block;
    padding: 15px 20px;
    text-decoration: none;
}

.list-nav-item.open .list-nav-header {
    color: #00a651;
}

.list-nav-item.open > .list-nav-header:hover {
    background-color: #e7e7e7 !important;
}

.nav-link-selected {
    font-weight: bold;
    background-color: #e7e7e7 !important;
    color: #00a651 !important;
}

.sub-list-nav-item {
    padding: 15px 15px 15px 35px;
    background-color: transparent;
    display: block;
    color: #5a5a5a;
    text-decoration: none;
}

.sub-list-nav-item:hover {
    text-decoration: none;
    /* color: black; */
}

.sub-list-nav-item:hover {
    background-color: #d7d7d7;
}

.side-nav-footer {
    bottom: 0;
    position: absolute;
    padding: 15px 20px;
    border-top: 1px solid #e2e2e2;
    width: 100%;
    background-color: #f9f9f9;
}

.side-nav-menu-hover {
    position: fixed;
    display: block;
}

.click-to-edit {
    background-color: transparent;
    border-color: transparent;
    border-radius: 6px;
    transition: 0.5s;
}

.click-to-edit:focus {
    background-color: white;
    border-color: grey;
}

.right-nav-body {
    padding: 15px;
}

.right-nav-body h5 {
    font-weight: bold;
    padding-top: 15px;
}

.click-to-edit:hover {
    background-color: #efefef;
    border-radius: 6px;
    border-color: transparent;
    color: #807e7e;
}

.info-label {
    display: block;
    font-size: 12px;
    padding-top: 5px;
    color: grey;
    font-weight: 450;
}

.label-input {
    border-radius: 9px;
    border: 1.5px solid #dde1e5;
}

.info-value {
    font-weight: bold;
    color: #525252;
    transition: 0.1s;
}

.info-value:hover {
    /* background-color:#e4e4e4;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 4px #e4e4e4; */
    cursor: text;
}

/* .info-edit {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #83a3ff;
} */

.opacity-0 {
    opacity: 0;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 100;
    }
    100% {
        opacity: 0;
    }
}
.faa-fade-in-out {
    animation: fade-in-out 1s ease-in-out;
}

.input-no-style {
    background-color: transparent;
    border: transparent;
    padding: 0;
    font-weight: bold;
    resize: none;
}

.info-edit::placeholder {
    font-weight: normal;
    font-style: italic;
    color: rgb(206, 206, 206);
}

.card-nml {
    transition: box-shadow 0.1s ease-in-out;
    cursor: pointer;
}

.card-nml:hover {
    -webkit-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
}

.nml-card-selected {
    border: 2px solid #00a651 !important;
    -webkit-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 50px;
    bottom: 10px;
    z-index: 50;
}

.nml-card-selected .card-header {
    color: white;
}

.main-content-col {
    height: 100%;
    top: 0px;
    bottom: 0px;
    overflow: auto;
    position: absolute;
}

.file-list {
    left: 0px;
    width: 50%;
}

.file-preview {
    right: 0px;
    width: 50%;
}

.file-content {
    margin-right: 220px;
    position: relative;
    height: 100%;
    overflow: auto;
}
@media print {
    .no-print {
        display: none;
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: white !important;
}

.dropright:hover > .dropdown-menu {
    left: 98% !important;
}

.list-nav-item .collapsible .collapsed {
    transform: scaleY(0);
}

.list-nav-item .collapsible {
    overflow: hidden;
    transition: transform 0.3s ease-out;
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
}

.ucc-card-header.collapsed {
    border-bottom-right-radius: 1em !important;
    border-bottom-left-radius: 1em !important;
    border-bottom: transparent;
}

.flex-bottom > .flex-stretch > .flex-button:first-child {
    border-bottom-left-radius: 1em;
}

.flex-bottom > .flex-stretch > .flex-button:last-child {
    border-bottom-right-radius: 1em;
}

.content-col {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    //overflow-y: auto;
}

.ucc_note_group > .list-group-item:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.ucc_note_group > .list-group-item:last-child {
    border-bottom-right-radius: 1em !important;
    border-bottom-left-radius: 1em !important;
}

.nml-section {
    border: 2px solid;
    border-radius: 1em;
    padding: 0.75em 1em;
    box-shadow: 0 7px 13px -3px rgba(0, 0, 0, 0.1);
}

.carousel-inner {
    overflow: visible !important;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.docuware-footer {
    padding-bottom: 1rem !important;
}

.docuware-footer > :not(:last-child) {
    margin-right: 0.25rem;
}

.list-group-item-selected {
    border: 2px solid rgb(0, 174, 239) !important;
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 70;
}

.list-nav-item:hover > .accordion > .collapse.show {
    background-color: transparent;
}

.short-dropdown-menu {
    max-height: 400px;
    overflow-y: scroll;
}

.dropdown-menu > .dropdown-item {
    cursor: pointer;
}

.file-drag-over {
    border: 2px solid lightblue;
    border-radius: 0.25rem;
    margin: 20px;
}

.notice-add-hover:hover {
    background-color: lightgray;
}

.bg-hover:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.message-list-item:hover {
    background-color: #dee2e6;
}

.message-selected {
    background-color: #dee2e6;
}

.chat-message {
    display: block;
    clear: both;
    max-width: 60%;
    white-space: pre-line;
}

.message-bubble {
    border-radius: 1rem;
    color: white;
    padding: 10px;
}

.message-sender {
    float: right;
    padding-right: 10px;
    text-align: right;
}

.message-sender > small {
    float: right;
}

.message-sender > .message-bubble {
    background-color: #1481ff;
}

.message-receiver > .message-bubble {
    background-color: darkgray;
}

.message-receiver {
    padding-left: 10px;
    float: left;
    text-align: left;
}

.message-receiver > small {
    float: left;
}

.chat-link {
    color: #ffffff;
    text-decoration: underline !important;
}

.message-attachment > .img-thumbnail {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.download-message-attachment {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
    margin-bottom: 5px;
}

.download-message-doc {
    min-width: 200px;
    text-align: left;
    border: 1px solid white;
    padding: 6px;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
}

.stack-icons {
    position: relative;
}

.stack-icons > .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 0.5em;
}

.reflektive-add-avatars {
    font-family: Graphik, system-ui !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 46px !important;
    background-color: rgb(194, 202, 210) !important;
    color: rgb(255, 255, 255) !important;
}
.reflektive-picture {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: relative;
    display: inline-block;
    overflow: hidden;
}
.reflektive-picture > img {
    width: auto;
    height: 100%;
    position: absolute;
}
.reflektive-rec-names {
    color: #8796a6;
    font-size: 9px;
    margin-top: 3px;
}
.reflektive-sender-name {
    font-family: Graphik, system-ui;
    font-weight: 500;
    font-size: 10px;
    line-height: 1;
    font-style: normal;
    color: #1f2539;
    display: inline-block;
    vertical-align: top;
}

.reflektive-scroll {
    overflow-y: scroll;
    height: calc(100vh - 155px);
}

.graph-widgets-scroll {
    overflow-y: scroll;
    height: calc(100vh - 98px);
}

#reflektive_col.collapse {
    height: auto;
    -webkit-transition: width 0.35s ease;
    -moz-transition: width 0.35s ease;
    -o-transition: width 0.35s ease;
    transition: width 0.35s ease;
}

.th-sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 90;
    // background-color: white !important;
}

.no-picture-avatar {
}

@media (max-width: 1200px) {
    .sortableTable-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.styled-input {
    border: 0;
    box-shadow: inset 0 0 0 0 white;
    display: inline-block;
    padding-left: 0;
}

.sortableTableStickyRow {
    position: sticky;
    background-color: white !important;
    bottom: 5px;
    z-index: 1020;
}

.labeled-input.required {
    content: '*';
    color: red;
}

.search-list > .row {
    border-top: 1px solid #ddd;
    padding: 8px;
}
.search-list > .row.even {
    background-color: #f9f9f9;
}

.search-list .list-item {
}

//.div-striped > .row {
//    border-top: 1px solid #ddd;
//    padding: 8px;
//}
//.div-striped > .row.even {
//    background-color: #f9f9f9;
//}

.list-outer {
    padding: 10px;
}

.dashboard-information > div {
    margin-bottom: 10px;
}

.invalid-value {
    color: red;
}
.search-name-icon {
    line-height: 60px;
}
.search-name-icon-taller {
    line-height: 75px;
}
.search-name-icon span {
    vertical-align: middle;
}
.search-name-icon-taller span {
    vertical-align: middle;
}
.search-company-icon-tall {
    vertical-align: middle;
    line-height: 60px;
}

.search-company-icon-taller {
    vertical-align: middle;
    line-height: 75px;
}

.search-company-icon-tall span {
    vertical-align: middle;
}
.search-company-icon-taller span {
    vertical-align: middle;
}

.search-name-text {
    display: inline-block;
    margin-left: 5px;
    margin-right: -5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.search-addr {
    margin-top: 10px;
    margin-bottom: 10px;
}
.search-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
}

.rightbar {
    background: rgba(249, 249, 249, 1);
    border-left: 1px solid #e2e2e2;
    max-width: 300px;
    transition-property: max-width, margin-right;
    transition-duration: 1s;
    position: fixed;
    z-index: 200;
    top: 45px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
}

.rightbar > .smallLabel {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 1s;
    transition-delay: 1s;
}

.faq-close {
    max-width: 0px;
    border-left: 1px solid #e2e2e2;
    background: rgba(226, 226, 226, 1);
    transition-property: max-width, margin-right;
    transition-duration: 1s;
}

.faq-close > .smallLabel {
    opacity: 0;
}

.faq-close.div {
    text-indent: 9999px;
}

.smallLabel {
    font-size: 0.8em;
}

.leftbar {
    background-color: #f9f9f9;
    border-right: 4px solid #e2e2e2;
    max-width: 340px;
    height: 1000px;
}

.section {
    margin-left: -15px;
    width: 338px;
    border-bottom: 4px solid #e2e2e2;
}

.section > h3 {
    max-width: 50px;
}

.inlineStepper {
    width: 80px;
    margin-left: 10px;
}

.checkCenter {
    text-align: center;
    width: 20px;
}

.uccPrefs h2 {
    color: #00aeef;
}

.lienPrefs h2 {
    color: #00a651;
}

.collPrefs h2 {
    color: #f7941d;
}
.faq-alert {
    opacity: 0;
    transition: opacity 0.15s linear;
}
.faq-alert.in {
    opacity: 1;
    transition: opacity 0.15s linear;
    position: fixed;
    top: 80px;
    right: 610px;
    z-index: 1080;
}

#app {
    margin: 0px 0px 30px 0px;
}

/* Options */
/*
.search-list {
    overflow-y: auto;
    overflow-x: hidden;
}
*/
a,
.clickable {
    cursor: pointer;
}

.clickable-table a {
    color: inherit;
}

.btn-spaced {
    margin: 2px;
}

.btn-row {
    width: 100%;
    overflow: hidden;
    margin-top: 16px;
    vertical-align: middle;
}

.btn-nav {
    display: inline-block;
    margin-right: 8px;
}

.btn-config {
    float: right;
}

.config-panel {
    margin-right: 1px;
    border: 3px solid #73ad21;
    float: right;
    padding-bottom: 17px;
}

.config-text {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    float: left;
}

.config-dropdown {
    margin-left: 15px;

    margin-right: 15px;
    margin-top: 10px;
    float: right;
}

.text-name {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    float: right;
}

.reporttable {
    min-width: 50%;
}
.btn-icon {
    vertical-align: middle;
}

.btn-text {
    display: inline-block;
    vertical-align: middle;
}

.clear-both {
    clear: both;
}

.detail-list {
    margin-top: 10px;
}

.btn-expand {
    float: left;
    margin-right: 8px;
}

.btn-collapse {
    float: left;
    margin-right: 6px;
    background-color: green;
    color: white;
}

.btn-collapse:hover {
    background-color: darkgreen;
    color: gray;
}

.btn-outline-dark {
    float: left;
    margin-right: 8px;
    pointer-events: none;
}

.report {
    margin-bottom: 25px;
}

.report-title {
    margin-bottom: 0px !important;
    display: inline-flex;
    align-items: center;
}

.title-box {
    display: flex;
    margin-bottom: 5px;
}

.sortable-table {
    margin-left: 0px;
    padding-left: 0px;
}

.sortable-table .table .header {
    display: table-header-group;
    border-bottom: 1px solid #d3d3d3;
}

.sortable-table.nodata {
    display: flex;
}

.hcol {
    background-color: #e5e8eb;
    border-color: #dfdfdf;
    justify-content: space-between;
    padding: 0 7px;
    height: 30px;
    align-items: center;
    color: #1f384d;
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.5);
}

.closed {
    display: none !important;
}

.btn-centered {
    display: flex;
    justify-content: center;
    margin: 2px;
}

.highlighted-value {
    background-color: yellow !important;
}

.table-custom {
    width: 100%;
    max-width: 100%;
}
.table-custom td {
    word-break: break-all;
    width: 30px;
}

.labeled-input {
    padding-right: 20px;
}

.save-menu {
    position: absolute;
    bottom: 0px;
}

.draft-state {
    color: grey;
}

#discard-tooltip {
    .tooltip-inner {
        background-color: #727b84;
    }
    .arrow::before {
        border-top-color: #727b84;
    }
}

.discard-arrow {
    color: #6b6b6b;
    margin-left: 5px;
    margin-right: 5px;
}

#publish-tooltip {
    .tooltip-inner {
        background-color: #6772f7;
    }
    .arrow::before {
        border-top-color: #6772f7;
    }
}

.utils-toggle span {
    vertical-align: middle;
}

.save-menu-bottom-right {
    position: fixed;
    bottom: 0px;
    right: -2px;
    background-color: #f9f9f9;
    border: 1px solid #e2e2e2;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 5px;
    margin: 9px;
    .btn-group {
        box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
        border-radius: 0.25rem;
    }
}

.publish-button {
    background-color: #7781ff;
    border-color: #7781ff;
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
        color: #fff;
        background-color: #636bda;
        border-color: #636bda;
    }
}

.summary-tag {
    background-color: #d2ffd2;
    padding: 1px 4px;
    border-radius: 5px;
    margin-right: 5px;
    color: #009b00;
    font-style: italic;
    font-weight: 500;
    font-size: 0.75rem;
}

.diff-button-bar {
    padding-top: 25px;
    padding-bottom: 13px;
    // background-color: white;
    label {
        // margin-right: 10px;
    }
    .custom-select {
        width: initial;
    }
    .nav-tabs {
        border-width: 0;
        border-bottom: 1px solid;
        border-image: linear-gradient(to right, #dee2e6, #ffffff00) 50 10%;
    }
}

.diff-mode-toggler {
    float: right;
}

.diff-content {
    position: relative;
}

.diff-header:after {
    content: '';
    position: absolute;
    z-index: 2000;
    top: 0px;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #f1f1f1;
    transform: translate(-50%);
}

.diff-content:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #f1f1f1;
    transform: translate(-50%);
}

.diff-header {
    margin: 5px;
    background-color: white;
    border-radius: 10px;
    position: relative;
    top: -15px;
    padding: 12px;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    text-align: right;
    h4 {
        padding-top: 4px;
    }
}

.diff-section {
    margin: 5px;
    border-radius: 10px;
    position: relative;
    padding: 12px;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #94d19400;
        background-color: #a1e9a1;
    }
    .custom-control-label::before {
        border: #dee6ed solid 1px;
    }
}

.section-header {
    font-weight: bold;
    .col {
        border-bottom: 1px solid #f3f3f3;
        margin-bottom: 20px;
        padding-bottom: 7px;
    }
}

.section-delete .section-header .section-header-after {
    border-bottom: 0px solid red;
}

.section-delete {
    .section-header-after {
        .section-label {
            color: #ff6969;
        }
    }
}

.save-menu-button {
    padding: 15px 20px;
    border-right: 1px solid #e2e2e2;
    text-align: center;
    background-color: #f9f9f9;
}

.save-menu-button.primary {
    background-color: blue;
}

.save-menu-button:hover {
    background-color: #eeeeee;
}

.save-menu-button:active {
    background-color: #eeeeee;
}

.changes-count {
    color: grey;
}

.changes-popup {
    height: 250px;
}

.changes-popup-body {
    font-size: 0.75rem;
}

.diff-page {
    position: relative;
}

.diff-modal-body {
    height: 750px;
    overflow: auto;
}

.diff-header-bar {
    margin-bottom: 20px;
    position: sticky;
    top: -25px;
    z-index: 50;
    height: 143px;
}

.diff-title {
    font-size: 1.5rem;
}

.diff-status-tag {
    padding: 3px;
    border-radius: 5px;
    color: white;
    font-size: 0.8rem;
    background-color: #959595;
    font-weight: 400;
    position: relative;
    top: -3px;
    margin-right: 5px;
    margin-left: 5px;
}

.diff-by-date {
    position: relative;
    top: -3px;
    font-style: italic;
    font-weight: 400;
}

#changes-popup.bs-popover-top {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    .popover-header {
        border-radius: 10px 10px 0px 0px;
        text-align: center;
        background-color: white;
        font-weight: bold;
    }
    .popover-body {
        height: 500px;
        width: 500px;
        font-size: 0.7rem;
        padding: 5px 25px;
        overflow: auto;
    }
}

.change-total-tab {
    margin-left: 8px;
    background-color: #dedede;
    padding: 2px;
    border-radius: 4px;
    font-size: 0.6rem;
}

.changes-popup-section {
    margin-bottom: 10px;
}

.popup-section-title {
    text-align: right;
    font-weight: bold;
}

.changes-popup-section-label {
    font-weight: bold;
    margin: 10px 0px;
    .fas {
        margin-right: 8px;
        color: #dcdcdc;
    }
}

.changes-popup-field-label div {
    text-align: right;
    // background-color: #f6f6f6;
    // color: #8e8e8e;
    margin-bottom: 5px;
    border-radius: 5px;
    // font-size: .6rem;
    white-space: nowrap;
}

.changes-popup-field-value {
    font-weight: 500;
    font-size: 0.7rem;
    text-align: left;
    white-space: initial;
    margin-right: 5px;
    margin-bottom: 5px;
}

.changes-popup-field-value.old {
    color: rgb(144 58 58) !important;
    text-decoration: line-through;
    text-decoration-thickness: 0.5px;
    // text-decoration-color: #5a5a5a;
    background-color: rgb(255 131 119 / 30%) !important;
}

.changes-popup-field-value.new {
    color: rgb(44 116 44) !important;
    background-color: rgb(100 255 100 / 34%) !important;
}

.changes-popup-section .row {
    margin-bottom: 2px;
}

#changes-popup {
    max-width: initial;
}

#changes-popup.bs-popover-top .arrow::before {
    border-top-color: white;
}

.popup-add {
}

.pop-up-delete {
}

.add-tag {
    margin-left: 10px;
    font-weight: normal;
    font-weight: initial;
    background-color: #94d294;
    padding: 2px 3px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.6rem;
}

.delete-tag {
    margin-left: 10px;
    font-weight: normal;
    font-weight: initial;
    background-color: #ff6161;
    padding: 2px 3px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 0.6rem;
}

.save-menu-bottom-right {
    .btn-primary {
        background-color: #7781ff;
        border-color: #7781ff;
    }
    .btn-primary:hover {
        background-color: #6772f7;
        border-color: #6772f7;
    }
}

.rotate-0 {
    transform: rotate(0deg);
    transition: all 0.2s ease-in;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: all 0.2s ease-in;
}

.section-add .section-header .section-header-before {
    border-bottom: 0px solid grey;
}

.section-add {
    .section-header-before {
        .section-label {
            color: grey;
        }
    }
}

.deleted-label {
    position: absolute;
    top: 0px;
    right: 25%;
    top: 50%;
    color: #ff8282;
    transform: translate(53%, -42%);
    font-size: 1.4109375rem;
    font-weight: 600;
}

.new-label {
    position: absolute;
    top: 0px;
    left: 18%;
    top: 50%;
    color: #d1d1d1;
    transform: translate(0%, -42%);
    font-size: 1.4109375rem;
    font-weight: 600;
}

.section-delete::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    border-radius: 0px 10px 10px 0px;
    border: 2px solid #ff6464;
    border-left: 2px dashed #ff6464;
    top: 0px;
    width: 50%;
    right: 0px;
    background-color: #ffefef;
    background: repeating-linear-gradient(45deg, #ffeaea, #ffeaea 10px, #ffdcdc 10px, #ffdcdc 20px);
}

.section-add::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    border-radius: 10px 0px 0px 10px;
    border: 2px solid #efefef;
    border-right: 2px dashed #efefef;
    top: 0px;
    width: 50%;
    left: 0px;
    background: repeating-linear-gradient(45deg, #fbfbfb, #fbfbfb 10px, #ffffff 10px, #ffffff 20px);
}

.section-label {
    font-size: 0.9rem;
}

.diff-field {
    color: #cfcfcf;
}

.field-label {
    text-align: right;
    font-style: italic;
}

.field-value {
}

.different {
    color: grey;
    .field-value {
        font-weight: bold;
    }
    .after-field {
        color: #3ed93e;
    }
}

.conflict-selected {
    background-color: #b8ffb8;
}

.conflict-rejected {
    text-decoration: line-through;
    text-decoration-thickness: 15%;
}

.diff-field .col-6:nth-child(1) {
    // border-right: 1px solid #f1f1f1;
}

.conflict-button {
    margin-right: 10px;
    padding-right: 10px;
    background-color: #efefef;
    padding: 1px 7px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 11px;
}

.max-500 {
    max-height: 500px;
    overflow: auto;
}

.loading {
    color: transparent;
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%;
    animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

span.expanding-input {
    display: inline;
    min-inline-size: 10%;
    max-inline-size: 25%;
    writing-mode: horizontal-tb;
    white-space: nowrap;
    overflow: hidden;
    width: initial;
}

.test-span {
    font-size: 1.14844rem;
}

// COLLAPSING HEADER

.collapsing-header {
    // position: sticky;
    width: 100%;
    // transition: 0.2s;
    // transition: 5s;
    position: absolute;
    top: 0;
    // top: var(--navbar-height);
    left: 0;
    right: 0;
    z-index: 90;
    // padding-top: 20px;
    margin-bottom: 0;
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0) 0px 10px 50px;
    padding-bottom: 0rem;

    &.collapsed {
        padding-top: 0.5rem;
    }
    &.scrolled {
        box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    }
    .collapsing-header-transition {
        transition: 5s;
    }
    .position-absolute-bottom {
        position: absolute;
        bottom: 0;
    }
}

#collapsingHeaderWrapper {
    .collapsing-header-sticky {
        transition: all 0.3s ease;
        position: sticky;
    }
}

.navbar-vertical-aside-mini-mode .collapsing-header {
    width: calc(100% - 5.75rem);
}

.collapsing-header-under {
    padding-top: 1rem;
    margin-bottom: 0;
    padding-bottom: 0rem;
}

.pin-button {
    opacity: 1;
    position: absolute;
    right: 1.20312rem;
    bottom: -1.20312rem;
    i {
        transition: 0.1s;
    }
}

.expand-button {
    opacity: 1;
    position: absolute;
    right: 3.80312rem;
    bottom: -1.20312rem;
    i {
        transition: 0.1s;
    }
}

.appear-on-collapse.before-first-scroll {
    display: none;
}

.filebar-title.expanded h3 {
    font-size: 1.1rem;
    margin-bottom: 0;
}

.collapsing-header .filebar-title.collapsed h3 {
    font-size: 0.98438rem;
    margin-bottom: 0;
}

.header-title-section {
    margin-bottom: 8px;
}
.collapsing-header.collapsed .header-title-section {
    margin-bottom: 0px;
}

.card-img-top .google-maps-embed {
    border-top-left-radius: 0.6875rem;
    border-top-right-radius: 0.6875rem;
    width: 100%;
}
.card-img-bottom .google-maps-embed {
    border-bottom-left-radius: 0.6875rem;
    border-bottom-right-radius: 0.6875rem;
    width: 100%;
}

.google-maps-satellite-toggle {
    position: absolute;
    width: 42px;
    height: 42px;
    bottom: 10px;
    left: 10px;
    z-index: 20;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
    &.dark-border {
        border: 2px solid black;
    }
    &:hover {
        border-width: 4px;
        margin: -2px;
        width: 46px;
        height: 46px;
    }
}

// Animated Typing Dots
.dot-flashing-wrapper {
    padding: 0px 10px;
    font-family: 'Font Awesome 5 Duotone';
}

.dot-flashing {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    // background-color: var(--bs-gray-300);
    color: var(--bs-gray-300);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    content: '●';
    width: 7px;
    height: 7px;
    border-radius: 5px;
    color: var(--bs-gray-700);
    animation: dotFlashing 1s infinite alternate;
}

.btn {
    .dot-flashing::before,
    .dot-flashing::after {
        top: -3px;
    }
}

.btn-xs {
    .dot-flashing::before,
    .dot-flashing::after {
        top: -1.5px;
    }
}

.dot-flashing::before {
    left: -10px;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 10px;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        // background-color: var(--bs-gray-300);
        color: var(--bs-gray-700);
    }

    50%,
    100% {
        // background-color: var(--bs-gray-100);
        color: var(--bs-gray-400);
    }
}

// FONT AWESOME STACK FIX
.fa-stack-fix {
    height: 1em !important;
    line-height: 1em !important;
}

.smaller {
    font-size: 0.78em;
}

.project-type-selector {
    .react-select__multi-value__remove {
        display: none;
    }
    &:not(.react-select--is-disabled) {
        .react-select__multi-value + .react-select__multi-value {
            .react-select__multi-value__remove {
                display: flex;
            }
        }
    }
}

.no-dropdown-toggle-arrow::after {
    display: none !important;
}

.backdrop-over-offcanvas {
    z-index: 1400;
}

.google-maps-button {
    background-color: #fff;
    display: block;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
    box-sizing: border-box;
    -webkit-transition: background-color .16s ease-out;
    transition: background-color .16s ease-out;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: 0px solid white;
  }
    
  .google-maps-button:active, .google-maps-button:hover, .google-maps-button:focus {
    background-color: white !important;
    color: black !important;
  }

@keyframes split-spin {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.ncs-logo-container {
     position: relative;
     width: 25px;
     height: 25px;
     margin: 0 auto;
     display:inline-block;
        animation-name: ncs-spin;
        animation-duration: 3s; /* Total duration of the animation */
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
      }
      
      @keyframes ncs-spin {
        0% {
          transform: rotate(0deg);
          animation-timing-function: ease-in;
        }
        
        50% {
          transform: rotate(360deg);
          animation-timing-function: linear;
        }
        
        100% {
          transform: rotate(1080deg);
          animation-timing-function: ease-out;
        }
      }

    

.ncs-logo-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-duration: 3s; /* Duration of the animation */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes move-diagonal-top-left {
    0% {
      transform: translate(-50%, -50%);
    }
    
    50% {
      transform: translate(-55%, -55%);
    }
    
    85% {
      transform: translate(-50%, -50%);
    }
  }
  
  @keyframes move-diagonal-top-right {
    0% {
      transform: translate(-50%, -50%);
    }
    
    50% {
      transform: translate(-35%, -50%);
    }
    
    85% {
      transform: translate(-50%, -50%);
    }
  }
  
  @keyframes move-straight-down {
    0% {
      transform: translate(-50%, -50%);
    }
    
    50% {
      transform: translate(-50%, -35%);
    }
    
    85% {
      transform: translate(-50%, -50%);
    }
  }
  
  /* Apply the animations to each image */
  .ncs-logo-container img:nth-child(1) {
    animation-name: move-diagonal-top-left;
  }
  
  .ncs-logo-container img:nth-child(2) {
    animation-name: move-diagonal-top-right;
  }
  
  .ncs-logo-container img:nth-child(3) {
    animation-name: move-straight-down;
  }

  .h-95{
    height: 95% !important;
  }
  
  
  .masonry-container {
    column-gap: 1em;
    margin: 0 auto;
  }
  
  /* For mobile devices */
  .masonry-container {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
  
  /* For desktops (min-width: 992px) and up */
  @media (min-width: 992px) {
    .masonry-container {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
  
  /* For large desktops (min-width: 1200px) and up */
  @media (min-width: 1200px) {
    .masonry-container {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
      max-width: 1750px;
      margin: 0 auto;
    }
  }
  
  .masonry-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 1em;
  }
  
  .overflow-x-hidden {
    overflow-x: hidden;
  }

  .overflow-y-auto {
    overflow-y: auto;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  .overflow-x-auto {
    overflow-x: auto;
  }
