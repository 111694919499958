@charset "UTF-8";

.app-theme-dark {
    background-color: var(--bs-body-bg);
    .bg-body {
        background-color: var(--bs-body-bg) !important;
    }

    .changes-popup-field-value.new {
        color: rgb(0 201 144) !important;
        background-color: rgba(0, 201, 167, 0.1) !important;
    }

    .changes-popup-field-value.old {
        color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
        background-color: rgba(237, 76, 120, 0.1) !important;
    }

    .dot-flashing::before,
    .dot-flashing::after {
        color: var(--bs-gray-300);
    }
    @keyframes dotFlashing {
        0% {
            // background-color: var(--bs-gray-300);
            color: var(--bs-gray-300);
        }

        50%,
        100% {
            // background-color: var(--bs-gray-100);
            color: var(--bs-gray-100);
        }
    }

    // MODAL
    .modal-content {
        border: 2px solid rgb(47 79 79 / 11%);
        box-shadow: rgb(9 9 9 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    }

    // TABLE
    table .fa-sort {
        color: #44494e;
    }

    // STEPS
    .step-item {
        $background: #25282a;
        $border: #2f3235;
        $shadow: #1e2022;

        .step-content-wrapper::before {
            box-shadow: 0px 0px 0px 12px $background, 0px 0px 0px 13px $border,
                0 0.1875rem 0.75rem 13px $shadow;
        }
        .step-selected {
            background-color: $background;
        }
    }

    //AVATAR
    .employee-avatar .avatar-background {
        filter: brightness(0.9);
    }
    .avatar-spinner {
        &:before {
            border-color: white;
        }
    }

    // BUTTON-GROUP
    .btn-group-segment {
        background-color: #2f3235;
    }

    //POPOVER
    $popover-border-color: #2f3235;
    .popover {
        border: 0.0625rem solid $popover-border-color;
    }
    .popover {
        border: 0.0625rem solid $popover-border-color;
    }
    .bs-popover-auto[data-popper-placement^='top'] > .popover-arrow:before,
    .bs-popover-top > .popover-arrow:before {
        border-top-color: $popover-border-color;
    }
    .bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow:before,
    .bs-popover-bottom > .popover-arrow:before {
        border-bottom-color: $popover-border-color;
    }
    .bs-popover-auto[data-popper-placement^='right'] > .popover-arrow:before,
    .bs-popover-end > .popover-arrow:before {
        border-right-color: $popover-border-color;
    }
    .bs-popover-auto[data-popper-placement^='left'] > .popover-arrow:before,
    .bs-popover-start > .popover-arrow:before {
        border-left-color: $popover-border-color;
    }
    .bs-popover-auto[data-popper-placement^='bottom'],
    .bs-popover-bottom {
        .popover-arrow:has(+ .popover-header) {
            &:after {
                border-bottom-color: transparent;
            }
        }
    }

    // LIST GROUP
    .list-group-item-action {
        color: white;
    }

    // INPUT
    input.form-control::placeholder {
        color: #91989e82;
    }

    // FLATPICKR DATE PICKER
    .flatpickr-wrapper {
        .flatpickr-calendar {
            .flatpickr-day {
                &.today {
                    border: 1px solid #e5e5e526;
                    &:not(.selected) {
                        color: #fff;
                        background-color: transparent;
                    }
                }
                &.nextMonthDay,
                &.prevMonthDay {
                    color: rgb(143 149 156 / 73%);
                }
                &.flatpickr-disabled {
                    color: #464646 !important;
                }
            }
            .flatpickr-months .flatpickr-prev-month:hover svg,
            .flatpickr-months .flatpickr-next-month:hover svg {
                fill: #c5c8cc !important;
            }
            .flatpickr-months .flatpickr-prev-month svg,
            .flatpickr-months .flatpickr-next-month svg {
                fill: #c5c8cc !important;
            }
            .flatpickr-month {
                .flatpickr-current-month {
                    .flatpickr-monthDropdown-months:hover {
                        color: white;
                    }
                    .cur-year:hover {
                        color: white;
                    }
                }
            }
        }
        .flatpickr-calendar.arrowBottom::before {
            border-bottom-color: #25282a;
        }
        .arrowUp,
        .arrowDown {
            border-color: transparent;
            &:hover {
                background-color: #e4e8ee1c !important;
            }
            &::after {
                border-top-color: #c5c8cc !important;
                border-bottom-color: #c5c8cc !important;
            }
        }
    }

    // TABS
    .nav-segment {
        background-color: #2a2d30;
    }
}
