$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
) !default;

%default-file-display {
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    padding: 1rem !important;
    padding-bottom: 3rem !important;
    height: 100%;
}

.file-display {
    @extend %default-file-display;
    //top:100px;
}

.file-display-extended {
    @extend %default-file-display;
    //top: 315px !important;
}

.file-display-not-editable {
    @extend %default-file-display;
    top: 100px;
}

.file-display-extended-not-editable {
    @extend %default-file-display;
    //top: 275px !important;
}

%file-display-side-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    transition: 0.5s;
    //background-color: #f9f9f9;
    padding: 1rem;
    overflow: auto;
}

.file-display-side-bar {
    @extend %file-display-side-bar;
    width: 0;
    padding: 0 !important;
}

.file-display-side-bar-open {
    @extend %file-display-side-bar;
    width: 40% !important;
}

%file-info {
    transition: 0.5s;
}

.file-info {
    @extend %file-info;
    width: 100%;
}

.file-info-small {
    @extend %file-info;
    width: 60% !important;
    padding-right: 1rem;
}

.only-office-editor-modal {
    max-width: initial;
    width: 95% !important;
    height: 95% !important;
    .modal-body {
        padding: 0;
    }
}

.review-section {
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px;
    border-radius: 10px;
    margin: 30px;
}

.review-dashboard {
    .changes-popup-body {
        font-size: 14px;
    }
    .changes-popup-field-value {
        font-weight: initial;
    }
    .changes-popup-field-label div {
        font-size: 11px;
    }
}
