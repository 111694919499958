:root {
    --navbar-height: 3.875rem;
    // --bs-warning-rgb: 250 193 127;
}

body {
    background-color: rgb(251, 252, 253);
}
.bg-body {
    background-color: rgb(251, 252, 253) !important;
}

// MODAL
.modal {
    z-index: 1047;
}
.modal-backdrop {
    z-index: 1046;
}
.offcanvas-backdrop {
    z-index: 1045;
}

// COLOR FIXES
.bg-warning {
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}
.bg-soft-warning {
    background-color: rgba(var(--bs-warning-rgb), 0.1) !important;
}

// FORM CONTROL
.form-control::placeholder {
    opacity: 0.3 !important;
}
.form-control:-ms-input-placeholder {
    opacity: 0.3 !important;
}

//CARD
.card {
    margin-bottom: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

// LAYOUT
.main {
    transition: padding 0.3s;
    transition-timing-function: ease-in-out;
}

.navbar-height {
    height: var(--navbar-height);
}

.portal-navbar {
    padding: 0px 35px;
    top: 0px;
}

.LOCAL {
    .portal-navbar {
        background-color: #3d8eb9 !important;
    }
}

.DEVELOPMENT {
    .portal-navbar {
        background-color: #721817 !important;
    }
}

.TESTING {
    .portal-navbar {
        background-color: #f09d51 !important;
    }
}

// .PRODUCTION{
//     .portal-navbar {
//             background-color: #3d8eb9 !important;
//     }
// }

// VERTICAL ASIDE //
.navbar-vertical-content {
    padding: 0rem 0.85rem;
}

.nav-vertical .nav-link.external-link {
    &::after {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-family: 'Font Awesome 6 Pro';
        font-weight: 300;
        content: '\f35d';
        padding-left: 1.25rem;
        margin-left: auto;
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 1rem 1rem;
        transition: 0.3s;
        color: var(--bs-secondary);
    }
}
.navbar-vertical-aside-mini-mode .nav-link.external-link::after {
    display: none;
}

.nav-vertical.nav-pills .nav-link {
    padding: 0.5rem 0.475rem !important;
}

.navbar-vertical.navbar .nav-subtitle-replacer {
    color: var(--bs-gray-300);
    padding-bottom: 0.15rem;
    padding-top: 1.25rem;
}

.navbar-vertical-aside .nav.nav-pills {
    padding: 0rem;
}

.vertical-aside-below-navbar {
    top: var(--navbar-height);
}

// Fix for this class defaulting to z-index: 2
.hs-sub-menu-desktop-lg {
    z-index: 1000;
}

// Fix for this being above the navbar despite having a lower z-index
.navbar-vertical-fixed {
    z-index: 98;
}

.vertical-aside-logo {
    font-size: 1.1rem;
    text-align: center;
    background-color: #fff0 !important;
}

// Fix for vertical aside also toggling top navbar's dropdown arrows
.navbar-vertical-aside-mini-mode .nav-link.dropdown-toggle::after {
    display: block;
}

.navbar-vertical-aside-mini-mode {
    .nav-link {
        justify-content: center;
    }
    .nav-icon {
        flex: initial;
    }
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside .nav-link.dropdown-toggle::after {
    display: none;
}

// Fix for vertical aside only shrinking if it is the main nav on the page
.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
    width: 5.75rem;
}

.sidebar-collapsed {
    width: 5.75rem;
}

.navbar-vertical-aside {
    transition: width 0.3s;
    transition-timing-function: ease-in-out;
}

.sidebar-header {
    overflow-y: auto;
}

.sidebar-header-title {
    transition: opacity 0.3s;
}

.transparent {
    opacity: 0;
}

.nav-link {
    align-items: center;
}

.sidebar-side-padding {
    padding: 0rem 1.5rem;
}

// TABS
.tab-content {
    margin-top: 1rem;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
}

.nav-segment {
    background-color: #f1f3f6;
}

.nav-segment,
.nav-pills {
    .nav-item button {
        border: none;
        background-color: transparent;
    }
}

// CARDS
.card-header .card-title {
    margin-bottom: 0;
}

// COLLAPSIBLE HEADER
.slim-label {
    display: block;
    text-transform: uppercase;
    color: #91989e;
    font-size: 9.375px;
    font-weight: bold;
    margin-bottom: -0.125rem;
}

// LIST GROUP HOVER TEXT FIX
.list-group-item-action:focus,
.list-group-item-action:hover {
    color: var(--bs-dark);
}

// MODAL STYLING TWEAKS + FIXES
.modal-footer {
    padding: 1.2rem;
}
.modal-content {
    border: 2px solid rgb(255 255 255 / 22%);
    box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 4%) 0px 10px 10px -5px;
}

// SWITCH
.form-switch-sm .form-check-input {
    width: 2.125em;
    height: 1.3125em;
    margin-top: 0.08rem;
}
.form-switch-xs .form-check-input {
    width: 1.825em;
    height: 1.1125em;
    margin-top: 0.18rem;
}

// MULTILINE TEXT TRUNCATE
%multi-line-truncate {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.truncate-lines-1 {
    @extend %multi-line-truncate;
    -webkit-line-clamp: 1;
}
.truncate-lines-2 {
    @extend %multi-line-truncate;
    -webkit-line-clamp: 2;
}
.truncate-lines-3 {
    @extend %multi-line-truncate;
    -webkit-line-clamp: 3;
}
.truncate-lines-4 {
    @extend %multi-line-truncate;
    -webkit-line-clamp: 4;
}
.truncate-lines-5 {
    @extend %multi-line-truncate;
    -webkit-line-clamp: 5;
}

// TABLE
table .fa-sort {
    color: #dde1ee;
}

.row.like-table {
    display: table-row;
}

.row.like-table > div {
    display: table-cell;
    vertical-align: middle;
    float: none;
}

// STEPS

// Doing this so you can use the step-icon class outside of a steps boundary
.step-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.step-item .step-content-wrapper {
    transition: background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: transparent;
    &::before {
        box-shadow: 0px 0px 0px 12px var(--bs-white), 0px 0px 0px 13px rgba(231, 234, 243, 0.7),
            0 0.375rem 0.75rem 13px rgb(140 152 164 / 8%);
        transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        opacity: 0;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        content: '';
    }
}

.step-item .step-content-wrapper.step-selected {
    background-color: var(--bs-white);
    &::before {
        opacity: 1;
    }
}

// For compatibility with selecting a step
.step .step-icon::after {
    z-index: -2;
}
.step-item {
    z-index: 1;
    transition: 0.3s ease-in;
}

// Last step item remove margin-bottom
.step .step-item:last-child {
    margin-bottom: 1rem;
}

// Allowing a step divider to transition from hidden to not
.step-divider::after {
    transition: opacity 0.3s ease-in;
    opacity: 1;
}
.hide-trail.step-item .step-divider::after {
    opacity: 0;
}

// TEXT
.text-all-caps {
    text-transform: uppercase;
}

.text-all-lower {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}

// Fix for disabled inputs still having pointer cursor
*[disabled] {
    cursor: auto;
}

// BUTTONS

// Fix for secondary ghost button miscolor on light theme
.app-theme-light {
    .btn-check:focus + .btn-ghost-secondary,
    .btn-ghost-secondary:focus,
    .btn-ghost-secondary:hover {
        color: #71869d;
    }

    .btn-check:active + .btn-ghost-secondary,
    .btn-check:checked + .btn-ghost-secondary,
    .btn-check:focus + .btn-ghost-secondary,
    .btn-ghost-secondary.active,
    .btn-ghost-secondary:active,
    .btn-ghost-secondary:focus,
    .btn-ghost-secondary:hover,
    .show > .btn-ghost-secondary.dropdown-toggle {
        color: #71869d;
        background-color: rgba(113, 134, 157, 0.1);
    }
}

// BUTTON-GROUP
.btn-group-segment {
    background-color: #f3f5f9;
}
.btn-group-xs > .btn,
.btn-xs {
    padding: 0.2rem 0.8rem;
    font-size: 0.6rem;
    border-radius: 0.3125rem;
}

.btn-soft-danger:disabled {
    color: white;
}

// AVATAR

// avatar size fix
.avatar-xxxs {
    width: 1.13125rem;
    height: 1.13125rem;
    .avatar-initials {
        width: 1.13125rem;
        height: 1.13125rem;
        font-size: 0.45625rem;
        line-height: 0;
    }
}
.avatar-xxs {
    width: 1.23125rem;
    height: 1.23125rem;
    .avatar-initials {
        width: 1.23125rem;
        height: 1.23125rem;
        font-size: 0.55625rem;
    }
}
.avatar-sm {
    width: 2rem;
    height: 2rem;
    .avatar-initials {
        width: 2rem;
        height: 2rem;
        font-size: 0.75rem;
    }
}
.avatar-group-sm .avatar {
    width: 2rem;
    height: 2rem;
}
.avatar-group-sm .avatar + .avatar {
    margin-left: -0.5rem;
}
.avatar-group-sm .avatar .avatar-initials {
    width: 2rem;
    height: 2rem;
    font-size: 0.75rem;
}
// Fix for soft background being slightly transparent in groups
.avatar-background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 50%;
}
.avatar {
    z-index: 1;
}
.avatar-initials {
    z-index: inherit;
}
.avatar-group .avatar + .avatar {
    // box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
}

.avatar-spinner {
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 50%;
    bottom: 50%;
    transform-origin: 100% 100%;
    transform: rotate(45deg) skewX(0deg); // Adust the skew to make minor adjustments to length of arc (must be negative value of below)
    z-index: 1;

    &:before {
        box-sizing: border-box;
        display: block;
        border: solid 1px grey;
        opacity: 0.8;
        width: 200%;
        height: 200%;
        border-radius: 50%;
        transform: skewX(
            0deg
        ); // Adust the skew to make minor adjustments to length of arc (must be positive value of above)
        content: '';
    }
}

.avatar-spinner-inside {
    top: -2px;
    left: -2px;
    & :before {
        border-width: 2px;
    }
}

//POPOVER
$popover-border-color: #e7eaf3;
.popover {
    border: 0.0625rem solid $popover-border-color;
}
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
    border-top-color: $popover-border-color;
}
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
    border-bottom-color: $popover-border-color;
}
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
    border-right-color: $popover-border-color;
}
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
    border-left-color: $popover-border-color;
}
.bs-popover-auto[data-popper-placement^='bottom'],
.bs-popover-bottom {
    .popover-arrow:has(+ .popover-header) {
        &:after {
            border-bottom-color: #f8fafd;
        }
    }
}

// POPOVER LIST
.popover-list .list-group-item {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
    }
}

// REACT-SELECT
.react-select-container {
    .react-select__group {
        &:last-child {
            padding-bottom: 0px;
        }
    }
}

// DIVIDER
.unlabeled-divider {
    &::before {
        margin-right: 0px;
    }
    &::after {
        margin-left: 0px;
    }
}

//Fixing table radius inside cards with no card-header and/or after table
.card > table:first-child > thead > tr {
    th:first-child {
        border-top-left-radius: var(--bs-card-inner-border-radius);
    }
    th:last-child {
        border-top-right-radius: var(--bs-card-inner-border-radius);
    }
}

.card > table:last-child > tbody > tr:last-child {
    td:first-child {
        border-bottom-left-radius: var(--bs-card-inner-border-radius);
    }
    td:last-child {
        border-bottom-right-radius: var(--bs-card-inner-border-radius);
    }
}

// FLATPICKR DATE PICKER
.flatpickr-wrapper {
    width: 100%;
    .flatpickr-hide-input.flatpickr-input {
        display: none;
        & + .flatpickr-calendar {
            margin-top: 0px;
        }
    }

    .flatpickr-calendar {
        &.inline {
            box-shadow: none;
        }
        .flatpickr-day {
            &.today {
                border: 1px solid rgba(231, 234, 243, 0.7);
                font-weight: bold;
                &:not(.selected) {
                    color: #1e2022;
                    background-color: transparent;
                }
            }
            &.nextMonthDay,
            &.prevMonthDay {
                color: #b2b6c1;
            }
            &.flatpickr-disabled {
                cursor: default;
                color: #e7eaf3 !important;
                &:hover {
                    background-color: transparent;
                }
            }
        }
        .numInputWrapper {
            margin-top: -3px;
            margin-left: 20px;
        }
        .flatpickr-months .flatpickr-prev-month:hover svg,
        .flatpickr-months .flatpickr-next-month:hover svg {
            fill: #828282;
        }
        .flatpickr-month {
            margin-top: 3px;
            display: flex;
            justify-content: center;
            .flatpickr-current-month {
                left: initial;
                .flatpickr-monthDropdown-months:hover {
                    color: black;
                }
                .cur-year:hover {
                    color: black;
                }
            }
        }
        .arrowUp,
        .arrowDown {
            border-color: transparent;
            display: block !important;
            opacity: 1 !important;
            border-radius: 5px;
            &:hover {
                background-color: #e4e8ee;
            }
            &::after {
                border-bottom-color: #677788 !important;
                border-top-color: #677788 !important;
            }
        }
        .flatpickr-disabled {
            &.flatpickr-prev-month,
            &.flatpickr-next-month {
                display: block;
                pointer-events: none;
                svg {
                    opacity: 0.3;
                }
            }
        }
    }
    .shortcut-buttons-flatpickr-buttons {
        border-radius: 0.3125rem;
        position: relative;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: middle;
        width: 100%;
        margin-top: 0.5rem;
        .shortcut-buttons-flatpickr-button {
            position: relative;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            background-color: #fff;
            border: 0.0625rem solid rgba(231, 234, 243, 0.7);
            --bs-btn-padding-x: 1rem;
            --bs-btn-padding-y: 0.6125rem;
            --bs-btn-font-size: 0.875rem;
            --bs-btn-font-weight: 400;
            --bs-btn-line-height: 1.5;
            --bs-btn-color: #677788;
            --bs-btn-bg: transparent;
            --bs-btn-border-width: 0.0625rem;
            --bs-btn-border-color: transparent;
            --bs-btn-border-radius: 0.3125rem;
            --bs-btn-hover-border-color: transparent;
            --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
                0 1px 1px rgba(0, 0, 0, 0.075);
            --bs-btn-disabled-opacity: 0.65;
            --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
            display: inline-block;
            padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
            font-family: var(--bs-btn-font-family);
            font-size: var(--bs-btn-font-size);
            font-weight: var(--bs-btn-font-weight);
            line-height: var(--bs-btn-line-height);
            color: var(--bs-btn-color);
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border-radius: var(--bs-btn-border-radius);
            background-color: var(--bs-btn-bg);
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
        button:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        button:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        :not(:first-child) {
            margin-left: -0.0625rem;
        }
    }
    .event {
        position: absolute;
        width: 3px;
        height: 3px;
        border-radius: 150px;
        bottom: 3px;
        left: calc(50% - 1.5px);
        content: ' ';
        display: block;
        background: #3d8eb9;
    }
}

// DROPDOWN
.dropdown-item .dropdown-icon {
    display: inline-block;
    width: 15px;
    text-align: center;
    margin-left: -5px;
    margin-right: 10px;
}

// MICRO APPS
.microapp-tabs {
    z-index: 200;
    .microapp-tab {
        background-color: #3e9c6c;
        color: white;
        transition: 0.3s;
        border: none;
        margin-bottom: -32px;
        margin-top: 5px;
        padding-bottom: 40px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;

        &:hover {
            margin-bottom: -28px;
            margin-top: 1px;
        }
        .microapp-close-button:hover {
            opacity: 0.5;
        }
    }
}

// WARNING COLOR CHANGES
.btn-warning {
    --bs-btn-color: #8e5d00;
    --bs-btn-bg: #fac17f;
    --bs-btn-border-color: #f5ca99;
    --bs-btn-hover-color: #8e5d00;
    --bs-btn-hover-bg: #f7d2a8;
    --bs-btn-hover-border-color: #f6cfa3;
    --bs-btn-focus-shadow-rgb: 208, 172, 130;
    --bs-btn-active-color: #8e5d00;
    --bs-btn-active-bg: #f7d5ad;
    --bs-btn-active-border-color: #f6cfa3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #8e5d00;
    --bs-btn-disabled-bg: #f5ca99;
    --bs-btn-disabled-border-color: #f5ca99;
}
