.pac-container{
    background-color:var(--bs-body-bg);
    position:absolute!important;
    z-index:1000;
    -webkit-border-radius:2px;
    -moz-border-radius:2px;
    border-radius:2px;
    border-top:none;
    font-family:var(--bs-body-font-family);
    -webkit-box-shadow:0 2px 6px rgba(0,0,0,.3);
    -moz-box-shadow:0 2px 6px rgba(0,0,0,.3);
    box-shadow:0 2px 6px rgba(0,0,0,.3);
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    overflow:hidden;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    color: var(--bs-dark);
    border-color: var(--bs-gray-200);
}
.pac-logo:after{
    content:"";
    padding:1px 1px 1px 0;
    height:18px;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    text-align:right;
    display:none;
    background-image:url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position:right;
    background-repeat:no-repeat;
    -moz-background-size:120px 14px;
    background-size:120px 14px
}
.hdpi.pac-logo:after{
    background-image:url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)
}
.pac-item{
    cursor:default;
    padding:5px 12px;
    -o-text-overflow:ellipsis;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    line-height:30px;
    text-align:left;
    border-top:none;
    font-size:11px;
    color: var(--bs-dark);
    // display: none;
}
.pac-item:hover{
    background-color:var(--bs-gray-300)
}
.pac-item-selected,.pac-item-selected:hover{
    background-color:var(--bs-gray-300)
}
.pac-matched{
    font-weight:700
}
.pac-item-query{
    font-size:13px;
    padding-right:3px;
    color: var(--bs-dark);
}
.pac-icon{
    width:15px;
    height:20px;
    margin-right:7px;
    margin-top:6px;
    display:inline-block;
    vertical-align:top;
    background-image:url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    -moz-background-size:34px;
    background-size:34px;
    display: none;
}
.hdpi .pac-icon{
    background-image:url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
.pac-icon-search{
    background-position:-1px -1px
}
.pac-item-selected .pac-icon-search{
    background-position:-18px -1px
}
.pac-icon-marker{
    background-position:-1px -161px
}
.pac-item-selected .pac-icon-marker{
    background-position:-18px -161px
}
.pac-placeholder{
    color:gray
}
.pac-target-input:-webkit-autofill{
    -webkit-animation-name:beginBrowserAutofill;
    animation-name:beginBrowserAutofill
}
.pac-target-input:not(:-webkit-autofill){
    -webkit-animation-name:endBrowserAutofill;
    animation-name:endBrowserAutofill
}